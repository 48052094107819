import React, { useState, useEffect, useMemo } from 'react';
import abbreviate from 'number-abbreviate';
import StarIcon from '../../images/star-icon.inline.svg';
import GitHubLogo from '../../images/github-logo.inline.svg';

import styles from './GithubLibraries.module.css';

const exceptionsMap = {
  'xml2': 'R',
  'numpy': 'Python',
  'jupyterlab': 'Python',
};

export const OctoCat = () => <GitHubLogo className={styles.octoCat} />

export const Library = ({ name, description, stars, url }) => {
  const starsAbbr = useMemo(() => abbreviate(stars), [stars]);

  return (
    <div className={styles.lib}>
      <div className={styles.libName}>
        <a href={url}>{name}</a>
        <span className={styles.starsCount}>
          <StarIcon className={styles.starIcon} />
          {starsAbbr}
        </span>
      </div>
      <p className={styles.libDesc}>{description}</p>
    </div>
  );
}

export const GithubLibraries = () => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    fetch('https://api.github.com/users/AlonaYunda/starred')
      .then(response => response.json())
      .then(resultData => {
        setResponse(resultData);
      })
      .catch(e => {
        setResponse({ error: e.message });
      })
  }, []);

  const data = useMemo(() => (
    Array.isArray(response) && response.reduce((result, current) => {
      if (exceptionsMap[current.name]) {
        current.language = exceptionsMap[current.name];
      }

      if (result[current.language]) {
        result[current.language].push(current);
      } else {
        result[current.language] = [current]
      }

      return result;
    }, {})
  ), [response]);

  if (response === null) {
    return <p>Loading...</p>;
  }

  if (response.error) {
    return <p>Failed to connect to GitHub.</p>
  }

  console.log(data);

  return (
    <div className={styles.libraries}>
      {Object.keys(data).map(language => (
        <div className="row-14">
          <div className={styles.language}>{language}</div>
          <div className={styles.list}>
            {data[language].map(lib => (
              <Library
                name={lib.name}
                description={lib.description}
                stars={lib.stargazers_count}
                url={lib.html_url}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}