import React, { FC, ReactNodeArray, useMemo } from 'react';

import styles from './Skills.module.css';

export const Skill = ({ image, children }) => {
  const imageSrc = useMemo(() => image ? require(`../../images/${image}`) : null, [image]);
  return (
    <div className={styles.skill}>
      <img className={styles.image} src={imageSrc} alt={children} title={children} />
    </div>
  );
};

export const Skills = ({ children }) => {
  return (
    <div className={styles.skills}>
      {children}
    </div>
  );
};
