import React from 'react';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Intro } from '../components/Intro';
import { IronOreFact } from '../components/IronOreFact';
import { Timeline, TimelineItem } from '../components/Timeline';
import { Articles } from '../components/Articles';
import { Footer } from '../components/Footer';
import { Skills, Skill } from '../components/Skills';
import { GithubLibraries, OctoCat } from '../components/GithubLibraries';
import { Conferences, Conference } from '../components/Conferences';

const IndexPage = () => (
  <Layout>
    <SEO title="About Me" />
    <Intro>
      I have been covering global steel and steelmaking raw material markets for over eight years. Started as a price reporter and then a team leader, and later switched to the research team in a senior analyst role. To enhance my analytical skills, I have enrolled in the CFA Program, passed the Level I exam and will resume studying for Level II in August 2020.
      <br /><br />
      I am currently focused on strengthening my modeling skills using R and Python, as well as broadening commodity markets knowledge with base metals. I am passionate about data-driven analysis and visualization and enjoy presenting findings of my research.
      <IronOreFact>
        In 2011 the Financial Times has speculated that iron ore is “more integral to the global economy than any other commodity, except perhaps oil”.
      </IronOreFact>
    </Intro>
    <h2>Career Timeline</h2>
    <Timeline>
      <TimelineItem date="Jun 2011">
        Graduated from Institute of Economics of the&nbsp;National Mining University of Ukraine with Master`s degree in Management of Foreign Economic Activity.
      </TimelineItem>
      <TimelineItem date="Oct 2011">
        Started at <a href="https://metalexpert.com">Metal Expert</a> as a market observer.
      </TimelineItem>
      <TimelineItem date="Oct 2013">
        Started at <a href="https://web.archive.org/web/20160528175156/https://www.steelfirst.com/#/web/20160528175156mp_/https://www.steelfirst.com/">Steel First</a> (part of <a href="https://www.euromoneyplc.com/">Euromoney</a>) as a price reporter.
      </TimelineItem>
      <TimelineItem date="Jun 2015">
        Moved to London, UK 🇬🇧
      </TimelineItem>
      <TimelineItem date="Jun 2016">
        Got promoted to a team leader at <a href="https://web.archive.org/web/20160528175156/https://www.steelfirst.com/#/web/20160528175156mp_/https://www.steelfirst.com/">Steel First</a>.
      </TimelineItem>
      <TimelineItem date="Aug 2016">
        <a href="https://web.archive.org/web/20160528175156/https://www.steelfirst.com/#/web/20160528175156mp_/https://www.steelfirst.com/">Steel First</a> reintegrated into <a href="https://web.archive.org/web/20160130201233/http://www.metalbulletin.com/Default.aspx">Metal Bulletin</a>.
      </TimelineItem>
      <TimelineItem date="Jul 2017">
        Switched to the research team at <a href="https://web.archive.org/web/20160130201233/http://www.metalbulletin.com/Default.aspx">Metal Bulletin</a> as a senior analyst.
      </TimelineItem>
      <TimelineItem date="Oct 2018">
        <a href="https://web.archive.org/web/20160130201233/http://www.metalbulletin.com/Default.aspx">Metal Bulletin</a> rebranded to <a href="https://www.fastmarkets.com/">Fastmarkets</a>.
      </TimelineItem>
      <TimelineItem date="Mar 2019">
        Enrolled into the <a href="https://www.cfainstitute.org/programs/cfa">CFA Programm</a>.
      </TimelineItem>
      <TimelineItem date="Dec 2019" badge="cfa-badge.png" badgeWidth="150">
        Passed CFA Level I exam, scored on 90th percentile.
      </TimelineItem>
    </Timeline>
    <h2>Publications</h2>
    <Articles />
    <h2>Presentations</h2>
    <Conferences>
      <Conference dates="16–18 Sep 2019" location="Athens, Greece">
        <a href="https://www.metallics.org/">International Iron Metallics Assn</a> meeting,<br />
        “Trends in consumption of ferrous scrap versus iron metallics globally”
      </Conference>
      <Conference dates="23–25 Jun 2019" location="Bali, Indonesia">
        <a href="https://www.coaltrans.com/events/25th-coaltrans-asia/overview">Coaltrans Asia</a>,<br />
        “A hard look at coking coal – Asian metallurgical coal market update”
      </Conference>
      <Conference dates="5–7 Jun 2019" location="Lisbon, Portugal">
        <a href="https://www.metalbulletin.com/events/international-iron-ore-symposium/details.html">International Iron Ore Symposium</a>,<br />
        “China’s appetite for higher-grade material and usage of ferrous scrap”
      </Conference>
      <Conference dates="16–17 Apr 2019" location="Cairo, Egypt">
        <a href="https://www.metalbulletin.com/events/north-africa-iron-and-steel-conference/details.html">North African Iron & Steel conference</a>,<br />
        “Metallics vs. scrap – achieving the right mix”, <a href="https://www.metalbulletin.com/events/download.ashx/document/speaker/E001700/a0I1t000001snjvEAA/Presentation">PDF</a>
      </Conference>
      <Conference dates="10–12 Dec 2018" location="Dubai, UAE">
        <a href="https://www.metalbulletin.com/events/details/e001563/22nd-middle-east-iron-and-steel-conference/details.html">Middle East Iron & Steel conference</a>,<br />
        “Chinese iron ore market developments and the role of higher-grade material”
      </Conference>
    </Conferences>
    <Footer>
      <h2>Software Skills</h2>
      <Skills>
        <Skill image="r-logo.svg">R language</Skill>
        <Skill image="python-logo.svg">Python</Skill>
        <Skill image="excel-logo.svg">Microsoft Excel</Skill>
        <Skill image="power-point-logo.svg">Microsoft Power Point</Skill>
      </Skills>
      <h2><OctoCat />Libraries I use</h2>
      <GithubLibraries />
    </Footer>
  </Layout>
);

export default IndexPage;
