import React, { FC, ReactNodeArray, useMemo } from 'react';
import cn from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './Articles.module.css';

type Props = {
};

const articlesMap = {
  'a.png': {
    title: 'Oil, steel, scrap',
    link: 'https://www.metalbulletin.com/Article/3940953/Home/Do-oil-prices-still-correlate-with-scrap-and-steel.html',
  },
  'b.png': {
    title: 'Iron ore, metallics',
    link: 'https://www.metalbulletin.com/Article/3912324/2020-PREVIEW-Profitability-to-continue-driving-feedstock-procurement-strategies-of-Chinese-steel.html',
  },
  'c.png': {
    title: 'Coal, iron ore',
    link: 'https://www.metalbulletin.com/Article/3888871/Forecasts-Steelmaking-Raw-Materials/Demand-for-met-coal-imports-in-China-sensitive-to-iron-ore-procurement-strategies.html',
  },
  'd.png': {
    title: 'Ferrous scrap',
    link: 'https://www.metalbulletin.com/Article/3788395/Search-results/Is-Chinas-lean-toward-scrap-sustainable.html',
  },
  'e.png': {
    title: 'Ferrous scrap',
    link: 'https://www.metalbulletin.com/Article/3828265/Indian-scrap-imports-to-keep-rising-despite-launch-of-local-shredding-operations.html',
  },
  'f.png': {
    title: 'Pig iron',
    link: 'https://www.metalbulletin.com/Article/3815214/Channel/207044/Is-there-room-for-China-to-import-MPI-once-again.html',
  },
};

export const ArticleItem = ({ image }) => {
  const { title, link } = articlesMap[image.node.base];

  return (
    <div className={styles.article}>
      {title}
      <a className={styles.link} href={link}>
        <Img fixed={image.node.childImageSharp.fixed} alt={title} />
      </a>
    </div>
  );
};

export const Articles: FC<Props> = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "articles" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fixed(width: 295, height: 285) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className={cn('row-14', styles.articles)}>
      {data.allFile.edges.sort((a, b) => {
        if (a.node.base > b.node.base) {
          return 1;
        }
        if (a.node.base < b.node.base) {
          return -1;
        }

        return 0;
      }).map(image => (
        <ArticleItem image={image} />
      ))}
    </div>
  );
};
