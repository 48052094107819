import React, { FC, ReactNodeArray, useMemo } from 'react';
import cn from 'classnames';

import styles from './Timeline.module.css';

type Props = {
  children: ReactNodeArray,
};

export const TimelineItem = ({ date, badge, badgeWidth, children }) => {
  const badgeSrc = useMemo(() => badge ? require(`../../images/${badge}`) : null, [badge]);

  return (
    <div className={cn('row-14', styles.timelineItem)}>
      <section className={styles.text}>
        <div className={styles.date}>{date}</div>
        <div className={styles.event}>
          {children}
        </div>
      </section>
      {badgeSrc && (
        <section className={styles.badge}>
          <img className={styles.badgeImage} src={badgeSrc} width={badgeWidth} />
        </section>
      )}
    </div>
  )
}

export const Timeline: FC<Props> = ({ children }) => {

  return (
    <div className={styles.timeline}>
      {children}
    </div>
  );
};
