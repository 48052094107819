import React from 'react';

import styles from './Conferences.module.css';

export const Conference = ({ dates, location, children }) => {
  return (
    <div className={styles.conference}>
      <div className={styles.dates}>
        {dates}
        <br />
        {location}
      </div>
      <div className={styles.name}>
        {children}
      </div>
    </div>
  );
}

export const Conferences = ({ children }) => {
  return (
    <div className={styles.conferences}>
      {children}
    </div>
  );
};