import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { FC, ReactNodeArray } from 'react';
import cn from 'classnames';
import Img from 'gatsby-image';

import styles from './IronOreFact.module.css';

type Props = {
  children: ReactNodeArray
}

export const IronOreFact: FC<Props> = ({ children }) => {
  const ironOreImg = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "iron-ore.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <figure className={styles.ironOreFact}>
      <Img
        className={styles.image}
        fluid={ironOreImg.file.childImageSharp.fluid}
        alt="Iron ore"
        title="Iron ore"
      />
      <figcaption className="small">
        {children}
      </figcaption>
    </figure>
  );
}
