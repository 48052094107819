import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { FC, ReactNode, ReactNodeArray } from 'react';
import cn from 'classnames';
import Img from 'gatsby-image';
import { IronOreFact } from '../IronOreFact';

import styles from './Intro.module.css';

type SortedChildren = {
  textNodes: Array<ReactNode>,
  ironOreFact: ReactNode | null,
};

type Props = {
  children: ReactNodeArray,
};

export const Intro: FC<Props> = ({ children }) => {
  const { textNodes, ironOreFact } = children.reduce((result: SortedChildren, current: ReactNode) => {
    if (React.isValidElement(current) && current.type === IronOreFact) {
      result.ironOreFact = current;
    } else {
      result.textNodes.push(current);
    }
    return result;
  }, { textNodes: [], ironOreFact: null });

  return (
    <div className={cn('row-14', styles.intro)}>
      <p className={cn('heroText', styles.introText)}>
        {textNodes}
      </p>
      {ironOreFact}
    </div>
  );
}
